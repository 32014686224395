import * as React from "react";
import * as style from "../styles/page.module.css";
import "../styles/theme.module.css";
import Navbar from "./navbar.jsx";
import Footer from "./footer.jsx";

const Page = (props) => {
  const { children, excludeNavbar, excludeFooter } = props;
  return (
    <div className={style.container}>
      {!excludeNavbar && <Navbar />}
      <main>{children}</main>
      {!excludeFooter && <Footer />}
    </div>
  );
};

export default Page;

export const Head = () => <html lang="it" />;
