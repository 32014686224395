import * as React from "react";
import * as style from "../styles/navbar.module.css";
import logo from "../images/logo-white pisa.png";

const Navbar = ({ fields, ...props }) => {
  return (
    <nav {...props}>
      <img src={logo} alt="Logo di TEDx Pisa" className={style.logo} />
    </nav>
  );
};

export default Navbar;
